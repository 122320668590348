import { React, useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import VehicleForm from "./Forms/VehicleForm";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import BasicUserInfo, { DATA_GRID_STYLES } from "../../components/BasicUserInfo";
import { COLORS } from "../../utils/colors";
import { sub } from "date-fns";

const VehicleSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editVehicleState, setEditVehicleState] = useState(false);

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicleOwner, setVehicleOwner] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      try {
        const response = await api("v1/vehicle/" + params.idVehicle, "GET", null);
        if (response.data.success) {
          // console.log(response.data.data.partnerCompany);

          const activeSubscriptions = await api(`v1/subscription/search`, "POST", { query: { vehicle: params.idVehicle } });
          if (activeSubscriptions.data.success) {
            response.data.data.vehicle.activeSubscriptions = activeSubscriptions.data.data.searchResult;
          } else {
            response.data.data.vehicle.activeSubscriptions = null;
          }

          const updatedProductInfo = await Promise.all(
            response.data.data.vehicle.products.map(async (product) => {
              const fetchProductInfo = await api("v1/product/" + product._id, "GET");
              let productData = null;
              if (fetchProductInfo.data.success) {
                productData = fetchProductInfo.data.data.product;
              }
              return productData;
            })
          );
          response.data.data.vehicle.products = updatedProductInfo;

          setSelectedVehicle(response.data.data.vehicle);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };
    fetchVehicleDetails();
  }, []);

  useEffect(() => {
    const fetchVehicleOwnerDetails = async () => {
      if (selectedVehicle != null) {
        try {
          let response = await api("v1/customer/" + selectedVehicle.customer, "GET", null);
          if (response.data.success) {
            console.log(response.data.data);
            response.data.data.customer.vehicles = null;
            setVehicleOwner(response.data.data.customer);
            setLoading(false);
          }
        } catch (error) {
          console.error("API error:", error);
        }
      }
    };
    fetchVehicleOwnerDetails();
  }, [selectedVehicle]);

  const handleDeleteVehicleConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/vehicle/" + selectedVehicle._id, "DELETE", null);
      if (response.data.success) {
        window.location.replace("/vehicle");
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editVehicle = () => {
    setEditVehicleState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadVehicleDetail = async (updatedVehicleDetails) => {
    setEditVehicleState((prevValue) => !prevValue);

    const updatedProductInfo = await Promise.all(
      updatedVehicleDetails.products.map(async (product) => {
        const fetchProductInfo = await api("v1/product/" + product._id, "GET");
        let productData = null;
        if (fetchProductInfo.data.success) {
          productData = fetchProductInfo.data.data.product;
        }
        return productData;
      })
    );

    updatedVehicleDetails.products = updatedProductInfo;
    setSelectedVehicle(updatedVehicleDetails);
  };

  console.log("editVehicleState", editVehicleState);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }

  console.log("SELECTED VEHICEL", selectedVehicle);
  return (
    selectedVehicle && (
      <div className="page partner-user-single w-full flex flex-column items-center min-h-h-screen pt-4 gap-8 p-8">
        {loading && <GlobalLoadingSpinner />}

        {/* Page Title */}
        <div className="pageTitle w-full flex justify-between">
          <div>
            <h1>Vehicle Details</h1>
          </div>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {/* Vehicle Single Content */}
        <div className="w-full flex flex-column md:flex-row  gap-8">
          <div className="w-full md:w-[500px]">
            <h2 className="font-semibold">Vehicle</h2>
            <div className={DATA_GRID_STYLES}>
              <span>REGO</span>
              <span>{selectedVehicle.rego}</span>
              <span>VIN</span>
              <span>{selectedVehicle.vin}</span>
              <span>MAKE</span>
              <span>{selectedVehicle.make}</span>
              <span>MODEL</span>
              <span>{selectedVehicle.model}</span>
              <span>MODEL YEAR</span>
              <span>{selectedVehicle.modelYear}</span>
              <span>AGE</span>
              <span>{selectedVehicle.age}</span>
              <span>Purchase Order Number</span>
              <span>{selectedVehicle.poNumber}</span>
              <span>Stock Code</span>
              <span>{selectedVehicle.stockCode}</span>
              <span>PRODUCTS</span>
              <div className="flex align-center ">
                {selectedVehicle.products.map((field, index) =>
                  field ? (
                    <span className="m-auto p-1 bg-white border-solid border-1 border-[#E0E0E0] rounded-lg" key={index}>
                      <a
                        href="javascript:void(0);"
                        onClick={() =>
                          sessionStorage.getItem("selectedPortal") != "Customer" && navigate("/product/" + field._id)
                        }
                      >
                        <strong>{field.name} </strong>
                      </a>
                    </span>
                  ) : (
                    ""
                  )
                )}
              </div>
              <span>ACTIVE</span>
              <span>{selectedVehicle.active ? "Yes" : "No"}</span>
              <span>CREATED ON</span>
              <span>{formatDate(selectedVehicle.createdOnUTC)}</span>
            </div>
            <div>
              {(sessionStorage.getItem("selectedPortal") == "Administrator" ||
                sessionStorage.getItem("selectedPortal") == "Customer") && (
                  <div className="actionItems flex gap-4">
                    <Button
                      size="large"
                      sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                      className="self-start m-4"
                      variant="contained"
                      onClick={editVehicle}
                    >
                      Edit Vehicle
                    </Button>
                    {sessionStorage.getItem("selectedPortal") == "Administrator" && (
                      <Button
                        size="large"
                        className="self-start bg-red-600 m-4"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          toggleDisplayDeleteConfirm(true);
                        }}
                      >
                        Delete Vehicle
                      </Button>
                    )}
                  </div>
                )}
            </div>
          </div>
          <div className="w-full md:w-[500px]">
            <h2 className="font-semibold">Customer</h2>
            {vehicleOwner && <BasicUserInfo user={vehicleOwner} />}
          </div>
        </div>

        {(sessionStorage.getItem("selectedPortal") == "Administrator" ||
          sessionStorage.getItem("selectedPortal") == "Customer") &&
          selectedVehicle?.activeSubscriptions?.length > 0 && (
            <div className="subscriptions-of-vehicle w-full md:w-[500px] flex flex-column self-start border p-2 shadow">
              <h3>Active Subscriptions</h3>
              <div className="flex flex-wrap gap-5">
                {selectedVehicle.activeSubscriptions.map((subscription) => {
                  const activeClass = subscription.active ? "" : "inactive";
                  return (
                    <div
                      className={
                        "flex flex-column justify-center items-center cursor-pointer border p-2 hover:bg-sky-100 hover:ring-sky-500"
                      }
                      onClick={() => {
                        navigate(`/subscription/${subscription._id}`);
                      }}
                    >
                      <span
                        className={`${subscription.active ? "bg-teal-500" : "bg-rose-500"
                          } text-white border rounded px-1 flex justify-center self-end`}
                      >
                        {subscription.active ? "Active" : "Not Active"}
                      </span>
                      <img src={subscription?.qrcode} />
                      <p>{subscription._id}</p>
                      {/* <p>{subscription._id}</p> */}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        {/* // Edit Modal HTML */}

        <ModalPopup
          showModal={editVehicleState}
          handleShowModal={editVehicle}
          popupTitle={"Edit Vehicle"}
          popupContent={
            <VehicleForm selectedVehicle={selectedVehicle} callback={reloadVehicleDetail} closeModal={editVehicle} />
          }
          cancelButtonText=""
          confirmButtonText=""
          confirmCallbackAction=""
        />

        {/* Delete Vehicle Confimation Modal */}
        <ModalPopup
          showModal={deleteConfirmationIsOpen}
          handleShowModal={toggleDisplayDeleteConfirm}
          popupTitle={"Delete Vehicle"}
          popupContent={
            <div>
              Are you sure you want to delete this vehicle:
              <strong>
                <em> {"REGO: " + selectedVehicle.rego + "\r\n VIN: " + selectedVehicle.vin} </em>
              </strong>
              ?
            </div>
          }
          cancelButtonText={"Cancel"}
          confirmButtonText={"Delete"}
          confirmCallbackAction={handleDeleteVehicleConfirm}
        />
      </div>
    )
  );
};

export default VehicleSingle;
