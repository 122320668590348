import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button, TextField } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import SubscriptionForm from "./Forms/SubscriptionForm";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../utils/colors";
import { api } from "../../utils/api";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import EmptyState from "../EmptyStates/EmptyState";

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

  return formattedDate;
}

const Subscription = () => {
  const [subscriptionItems, setSubscription] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
  // const { filteredData, search, setSearch } = useBasicSearch(subscriptionItems);
  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  useEffect(() => {
    if (search.length > 2) {
      searchSubscriptions(search);
    } else {
      fetchSubscriptions();
    }
  }, [search, currentPage]);


  const fetchSubscriptions = async () => {
    try {

      let endpoint = "v1/subscription";
      if (sessionStorage.getItem("selectedPortal") == "Customer") {
        const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        endpoint = "v1/subscription/customer/" + currentUser._id;
      }
      if (sessionStorage.getItem("selectedPortal") == "Partner") {
        endpoint = `v1/partner/company/${sessionStorage.getItem("selectedCompany")}/subscriptions`;
      }
      const response = await api(`${endpoint}?page=${currentPage}`, "GET");
      if (response.data.success) {
        const subscriptions = response.data.data.allSubscriptions;
        setSubscription(subscriptions);

        // Update the total pages based on the total records and page size
        setTotalPages(Math.ceil(response.data.data.totalRecords / pageSize));
        /*
        const updatedSubscriptions = await Promise.all(
          subscriptions.map(async (subscription) => {
            try {
              const vehicleResponse = await api(`v1/vehicle/${subscription.vehicle}`, "GET");
              const productResponse = await api(`v1/product/${subscription.product}`, "GET");
              console.log("vehicleResponse", vehicleResponse);
              console.log("productResponse", productResponse);
 
              // const redemptionIds = subscription.redemptions; // Assuming vehicle.products is an array of product IDs
              // const redemptionPromises = redemptionIds.map(async (redemptionId) => {
              //   const fetchRedemptionInfo = await api("v1/redemption/" + redemptionId, "GET");
              //   if (fetchRedemptionInfo.data.success) {
              //     const redemption = fetchRedemptionInfo.data.data.redemption;
              //     return (redemption.redemptionDateUTC)
              //   } else {
              //     return null;
              //   }
              // });
 
              // const updatedRedemptions = await Promise.all(redemptionPromises);
              // console.log("Vehicle and Product Resp", vehicleResponse, productResponse);
 
              if (vehicleResponse.data.success && productResponse.data.success) {
                const { rego, vin } = vehicleResponse.data.data.vehicle;
                const { name, type, subscriptionDuration, price, rrp, active } = productResponse.data.data.product;
                return {
                  ...subscription,
                  vehicle: vehicleResponse.data.data.vehicle.vin + (vehicleResponse.data.data.vehicle.rego ? " (" + vehicleResponse.data.data.vehicle.rego + ")" : ""),
                  // vehicle: [
                  //   'REGO: ' + rego,
                  //   'VIN: ' + vin,
                  // ],
                  product: productResponse.data.data.product.name
                  // redemptions: updatedRedemptions,
                  // product: [
                  //   'Name: ' + name,
                  //   'Type: ' + type,
                  //   'Duration: ' + subscriptionDuration,
                  //   'Price: $' + price,
                  //   'RRP: $' + rrp,
                  //   'Active: ' + active,
                  // ],
                };
              }
            } catch (error) {
              console.error("API error:", error);
            }
            return subscription;
          })
        );
 
        setSubscription(updatedSubscriptions);
        */
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const searchSubscriptions = async (keyword) => {
    setLoading(true);
    try {
      const response = await api("v1/subscription/search", "POST", { keyword, page: currentPage, limit: pageSize });
      if (response.data.success) {
        const subscriptions = response.data.data.searchResult;
        setTotalPages(Math.ceil(subscriptions.length / pageSize));
        setSubscription(subscriptions);
      } else {
        setSubscription([]);
      }
    } catch (error) {
      console.error("API error:", error);
      setSubscription([]);
    }
    setLoading(false);
  };
  /*
  const updateSubscriptionDetails = async (subscriptions) => {
    try {
      const updatedSubscriptions = await Promise.all(
        subscriptions.map(async (subscription) => {
          try {
            const vehicleResponse = await api(`v1/vehicle/${subscription.vehicle}`, "GET");
            const productResponse = await api(`v1/product/${subscription.product}`, "GET");

            if (vehicleResponse.data.success && productResponse.data.success) {
              const { rego, vin } = vehicleResponse.data.data.vehicle;
              const { name } = productResponse.data.data.product;
              return {
                ...subscription,
                vehicle: `${vin} (${rego || ''})`,
                product: name,
              };
            }
          } catch (error) {
            console.error("API error:", error);
          }
          return subscription;
        })
      );

      setSubscription(updatedSubscriptions);
      setLoading(false);
    } catch (error) {
      console.error("API error:", error);
      setLoading(false);
    }
  };
  */
  const triggerSubscriptionListUpdate = (subscription) => {
    console.log("New/Updated Subscription: ", subscription);
    setShowSubscriptionForm(false);
    setLoading(false);
    fetchSubscriptions();
  };

  return (
    <div className="page subscription w-full flex flex-column items-center p-4">
      {loading && <GlobalLoadingSpinner />}
      <div className="pageTitle flex flex-col w-full text-center mb-4 lg:flex-row lg:text-left lg:justify-between">
        <h2>Subscriptions</h2>
        {sessionStorage.getItem("selectedPortal") !== "Customer" && (
          <div className="pageActions">
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ width: "300px" }}
              placeholder="Search for a subscription"
            />
            <Button
              sx={{ backgroundColor: COLORS.priorityBlue.hex }}
              variant="contained"
              onClick={() => setShowSubscriptionForm(true)}
            >
              Add New Subscription
            </Button>
            <ModalPopup
              className="w-full"
              showModal={showSubscriptionForm}
              handleShowModal={() => setShowSubscriptionForm(false)}
              popupTitle="Add New Subscription"
              popupContent={
                <SubscriptionForm
                  callback={triggerSubscriptionListUpdate}
                  cancelModal={() => setShowSubscriptionForm(false)}
                />
              }
            />
          </div>
        )}
      </div>
      <div className="flex w-full">
        <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
          <div className="flex flex-column gap-4">
            {subscriptionItems.length > 0 ? (
              <div className="flex flex-wrap gap-4 justify-center lg:justify-normal">
                {subscriptionItems.map((subscription) => (
                  <SubscriptionCard {...subscription} key={subscription._id} />
                ))}
              </div>
            ) : <EmptyState type="merchantAccounts" text="No Subscription match your search." />
            }
            {totalPages > 1 && (
              <div className="pagination flex justify-center items-center gap-2 flex-wrap">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Button
                    key={index}
                    variant={currentPage === index + 1 ? "contained" : "outlined"}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionCard = ({ vehicle, product, validUntilDateUTC, nextBillingDateUTC, active, _id }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col border border-1 shadow-sm w-[400px] p-4">
      <div className="grid grid-cols-2 mb-4">
        <span className="font-semibold">Vehicle</span>
        <span>{vehicle.rego} {vehicle.vin ? `(${vehicle.vin})` : ''}</span>
        <span className="font-semibold">Product</span>
        <span>{product.name}</span>
        <span className="font-semibold">Valid Until</span>
        <span>{validUntilDateUTC ? formatDate(validUntilDateUTC) : "n/a"}</span>
        <span className="font-semibold">Next Billing Date</span>
        <span>{nextBillingDateUTC ? formatDate(nextBillingDateUTC) : "n/a"}</span>
        <span className="font-semibold">Activated</span>
        <span>{active ? "YES" : "NO"}</span>
      </div>
      <Button
        onClick={() => navigate(`/subscription/${_id}`)}
        sx={{ backgroundColor: COLORS.priorityBlue.hex }}
        variant="contained"
      >
        View Details
      </Button>
    </div>
  );
};

export default Subscription;
